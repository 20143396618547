import { useState, useEffect } from 'react';
import './App.css';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import markdown from './content/home.md';

const Container = styled.div`
font-family: 'Montserrat', sans-serif;
background: #fff;
padding: 24px 48px;
position: fixed;
top: 24px;
left: 24px;
right: 24px;
bottom: 24px;
`

function App() {
  
  const [content, setContent] = useState("Loading");
  
  useEffect(() => {
    fetch(markdown)
      .then(res => res.text())
      .then(text => setContent(text));
  }, [setContent]);
  
  return (
    <Container>
      <ReactMarkdown>
        { content }
      </ReactMarkdown>
    </Container>
  );
}

export default App;
